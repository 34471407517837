<template>
  <v-container
    fluid
    style="background-color: #f4f4f5 !important"
    class="ma-0 pa-0"
  >
    <v-alert color="#79A2BD" dark class="text-center">
      <strong>{{ get_selected_company.company_name }}</strong>
    </v-alert>
    <v-row class="mt-5" justify="center" align="center">
      <v-card class="mt-5" elevation="2" width="70%">
        <v-card-title class="text-small">
          <small>Setup contact</small>
        </v-card-title>
        <!-- {{ get_contact_setup }} -->
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                label="First Name*"
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="get_contact_setup.first_name"
                @blur="update_contact()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                label="Email"
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="get_contact_setup.email"
                @blur="update_contact()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                label="last Name*"
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="get_contact_setup.last_name"
                @blur="update_contact()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="get_contact_list"
                item-value="id"
                item-text="name"
                return-object
                v-model="get_contact_setup.contact_type_id"
                outlined
                dense
                label="Contact type"
                @blur="update_contact()"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                :items="get_gender"
                item-value="id"
                item-text="name"
                return-object
                v-model="get_contact_setup.gender_id"
                outlined
                dense
                label="Gender"
                @blur="update_contact()"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field
                type="date"
                dense
                outlined
                label="Birthday Date"
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="get_contact_setup.date_of_birth"
                @blur="update_contact()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                type="number"
                dense
                outlined
                label="Age"
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="get_contact_setup.age"
                @blur="update_contact()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                type="number"
                dense
                outlined
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="get_contact_setup.mobile_number"
                label="mobile"
                @blur="update_contact()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                type="number"
                dense
                outlined
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="get_contact_setup.landline_number"
                label="phone"
                @blur="update_contact()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                type="number"
                dense
                outlined
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="get_contact_setup.other"
                label="other"
                @blur="update_contact()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                type="text"
                dense
                outlined
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="get_contact_setup.address"
                label="Address"
                @blur="update_contact()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="get_source"
                item-value="id"
                item-text="name"
                return-object
                v-model="get_contact_setup.source_id"
                outlined
                dense
                label="Source"
                @blur="update_contact()"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <strong style="fontsize: 20px">Tags</strong>
              <v-text-field
                dense
                outlined
                v-model="for_tags"
                label="Add a tag"
                prepend-inner-icon="mdi-tooltip-text-outline"
                class="mt-3"
                @keyup.enter="add_tags()"
              >
                <template slot="append">
                  <small class="mt-2">Press ENTER to add</small>
                </template>
              </v-text-field>
              <v-row>
                <v-col cols="12">
                  <div class="tags-wrapper">
                    <div
                      class="tags"
                      v-for="(tag, index) in get_contact_setup.c_tags"
                      :key="index"
                    >
                      <div class="tag-name">
                        {{ tag.name }}
                      </div>
                      <div class="tag-action">
                        <v-btn
                          color="error"
                          icon
                          @click="remove_element(tag.id)"
                        >
                          <v-icon dark> mdi-delete </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <strong style="fontsize: 20px">Notes</strong>
              <v-text-field
                dense
                outlined
                prepend-inner-icon="mdi-tooltip-text-outline"
                v-model="get_contact_setup.notes"
                label="Add a contact note"
                class="mt-3"
                @blur="update_contact()"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-5" elevation="2" width="70%">
        <v-card-title class="text-small">
          <small>Additional contact information</small>
        </v-card-title>
        <v-card-subtitle>
          Include more in-depth contact details here
        </v-card-subtitle>
        <v-card-text>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Passport Details</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Full name as on passport"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.passport_full_name"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Other name"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.passport_other_names"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      :items="get_country"
                      item-value="id"
                      item-text="name"
                      return-object
                      v-model="get_contact_setup.passport_country_origin_id"
                      outlined
                      dense
                      label="Passport country of origin"
                      @blur="update_contact()"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Passport number"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.passport_number"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Passport issue details"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.passport_issue_details"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      type="date"
                      dense
                      outlined
                      label="Expiry Date"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.passport_expiry_date"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Visa information"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.passport_visa_info"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Vaccination Details"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.passport_vaccination_details"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Passport notes"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.passport_note"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                >Insurance Details</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Travel Insurance provider"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.travel_insurance_provider"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Policy number"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.insurance_policy_number"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Insurance notes"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.insurance_notes"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                >Emergency Contact</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Full name"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.emergency_contact_name"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      :items="get_relationship"
                      item-value="id"
                      item-text="name"
                      return-object
                      v-model="get_contact_setup.emergency_relationships_id"
                      outlined
                      dense
                      label="Relationship with traveller"
                      @blur="update_contact()"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Email"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.emergency_email"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Phone"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.emergency_contact_number"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                >Licence Details</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Drivers licence details"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.drivers_licence_details"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Diving licence details"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.diving_licence_details"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Other licence details"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.other_licence_details"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                >Physical Details</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      type="number"
                      dense
                      outlined
                      label="Height"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.height"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      type="number"
                      dense
                      outlined
                      label="Weight"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.weight"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Clothing size"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.clothing_size"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Fitness level"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.fitness_level"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Cycling ability"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.cycling_ability"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Swimming ability"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.swimming_ability"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Health and mobility level"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.health_and_mobility"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                >Language Spoken</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      :items="get_language_spoken"
                      item-value="id"
                      item-text="name"
                      return-object
                      v-model="for_language_spoken"
                      outlined
                      dense
                      label="Add Language"
                      @keyup.enter="add_language"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div class="tags-wrapper">
                      <div
                        class="tags"
                        v-for="(tag, index) in get_contact_setup.c_language"
                        :key="index"
                      >
                        <div class="tag-name">
                          {{ tag.languagespoken.name }}
                        </div>
                        <div class="tag-action">
                          <v-btn
                            color="error"
                            icon
                            @click="remove_language(tag.id)"
                          >
                            <v-icon dark> mdi-delete </v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                >Special Requirements</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Allergies"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.allergies"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Dietary requirements"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.dietary_requirements"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Religious or cultural requirements"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="
                        get_contact_setup.religious_cultural_requirements
                      "
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Special assistance needed"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.special_assistance"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>Social Media</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Facebook profile"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.facebook_profile"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Instagram profile"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.instagram_profile"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="Wechat profile"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.wechat_profile"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      dense
                      outlined
                      label="LinkedIn profile"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.linkedin_profile"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Other social profile"
                      prepend-inner-icon="mdi-tooltip-text-outline"
                      v-model="get_contact_setup.other_profile"
                      @blur="update_contact()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: [],
  data: () => ({
    activePicker: null,
    date: null,
    menu: false,

    for_tags: null,
    for_language_spoken: null,
    panel: [0, 1, 2, 3, 4, 5, 6, 7],
  }),
  async mounted() {
    await this.$store.dispatch("contact/fetch_gender");
    await this.$store.dispatch("contact/fetch_country");
    await this.$store.dispatch("contact/fetch_language_spoken");
    await this.$store.dispatch("contact/fetch_relationship");
    await this.$store.dispatch("contact/fetch_source");
  },
  created() {},
  computed: {
    ...mapGetters({
      get_selected_company: "auth/get_selected_company",
      get_contact_list: "contact/get_contact_list",
      get_contact_setup: "contact/get_contact_setup",
      get_gender: "contact/get_gender",
      get_country: "contact/get_country",
      get_language_spoken: "contact/get_language_spoken",
      get_relationship: "contact/get_relationship",
      get_source: "contact/get_source",
    }),
  },
  methods: {
    async add_language() {
      const selectif = this.get_contact_setup["c_language"].filter((item) => {
        return item.language_spoken_id === this.for_language_spoken.id;
      });
      if (!selectif[0]) {
        if (this.for_language_spoken) {
          console.log(this.for_language_spoken);
          await this.$axios
            .post(`contacts/add_language`, {
              language_spoken_id: this.for_language_spoken.id,
              contact_id: this.get_contact_setup.id,
            })
            .then(({ data }) => {
              if (data.response) {
                this.$store.dispatch("contact/set_contact_setup", data.data);
              }
            });
        }
      }
      this.for_language_spoken = null;
    },
    async add_tags() {
      const selectif = this.get_contact_setup["c_tags"].filter((item) => {
        return item.name === this.for_tags;
      });

      if (!selectif[0]) {
        await this.$axios
          .post(`contacts/add_tag`, {
            name: this.for_tags,
            contact_id: this.get_contact_setup.id,
          })
          .then(({ data }) => {
            if (data.response) {
              this.$store.dispatch("contact/set_contact_setup", data.data);
            }
          });
      }
      this.for_tags = null;
    },
    async remove_element(id) {
      await this.$axios
        .patch(`contacts/tag_delete/${id}`, {
          id: id,
          contact_id: this.get_contact_setup.id,
        })
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch("contact/set_contact_setup", data.data);
          }
        });
    },
    async remove_language(id) {
      await this.$axios
        .patch(`contacts/language_delete/${id}`, {
          id: id,
          contact_id: this.get_contact_setup.id,
        })
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch("contact/set_contact_setup", data.data);
          }
        });
    },

    async update_contact() {
      console.log(this.get_contact_setup.gender_id);
      await this.$axios
        .patch(`contacts/update/${this.get_contact_setup.id}`, {
          company_id: this.get_contact_setup.company_id,
          first_name: this.get_contact_setup.first_name,
          email: this.get_contact_setup.email,
          last_name: this.get_contact_setup.last_name,
          contact_type_id: this.get_contact_setup.contact_type_id
            ? this.get_contact_setup.contact_type_id.id
            : this.get_contact_setup.contact_type_id,
          gender_id: this.get_contact_setup.gender_id
            ? this.get_contact_setup.gender_id.id
            : this.get_contact_setup.gender_id,
          date_of_birth: this.get_contact_setup.date_of_birth,
          age: this.get_contact_setup.age,
          mobile_number: this.get_contact_setup.mobile_number,
          landline_number: this.get_contact_setup.landline_number,
          other: this.get_contact_setup.other,
          address: this.get_contact_setup.address,
          source_id: this.get_contact_setup.source_id
            ? this.get_contact_setup.source_id.id
            : this.get_contact_setup.source_id,
          notes: this.get_contact_setup.notes,
          passport_full_name: this.get_contact_setup.passport_full_name,
          passport_other_names: this.get_contact_setup.passport_other_names,
          passport_country_origin_id: this.get_contact_setup
            .passport_country_origin_id
            ? this.get_contact_setup.passport_country_origin_id.id
            : this.get_contact_setup.passport_country_origin_id,
          passport_number: this.get_contact_setup.passport_number,
          passport_issue_details: this.get_contact_setup.passport_issue_details,
          passport_expiry_date: this.get_contact_setup.passport_expiry_date,
          passport_visa_info: this.get_contact_setup.passport_visa_info,
          passport_vaccination_details:
            this.get_contact_setup.passport_vaccination_details,
          passport_note: this.get_contact_setup.passport_note,
          travel_insurance_provider:
            this.get_contact_setup.travel_insurance_provider,
          insurance_policy_number:
            this.get_contact_setup.insurance_policy_number,
          insurance_notes: this.get_contact_setup.insurance_notes,
          emergency_contact_name: this.get_contact_setup.emergency_contact_name,
          emergency_relationships_id: this.get_contact_setup
            .emergency_relationships_id
            ? this.get_contact_setup.emergency_relationships_id.id
            : this.get_contact_setup.emergency_relationships_id,
          emergency_contact_number:
            this.get_contact_setup.emergency_contact_number,
          emergency_email: this.get_contact_setup.emergency_email,
          drivers_licence_details:
            this.get_contact_setup.drivers_licence_details,
          diving_licence_details: this.get_contact_setup.diving_licence_details,
          other_licence_details: this.get_contact_setup.other_licence_details,
          height: this.get_contact_setup.height,
          weight: this.get_contact_setup.weight,
          clothing_size: this.get_contact_setup.clothing_size,
          fitness_level: this.get_contact_setup.fitness_level,
          cycling_ability: this.get_contact_setup.cycling_ability,
          swimming_ability: this.get_contact_setup.swimming_ability,
          health_and_mobility: this.get_contact_setup.health_and_mobility,
          allergies: this.get_contact_setup.allergies,
          dietary_requirements: this.get_contact_setup.dietary_requirements,
          religious_cultural_requirements:
            this.get_contact_setup.religious_cultural_requirements,
          special_assistance: this.get_contact_setup.special_assistance,
          facebook_profile: this.get_contact_setup.facebook_profile,
          instagram_profile: this.get_contact_setup.instagram_profile,
          wechat_profile: this.get_contact_setup.wechat_profile,
          linkedin_profile: this.get_contact_setup.linkedin_profile,
          other_profile: this.get_contact_setup.other_profile,
        })
        .then(({ data }) => {
          console.log(data);
          if (data.response) {
            this.$store.dispatch("contact/set_contact_setup", data.data);
          }
        });
    },
  },
  watch: {},
};
</script>

<style scoped>
.card {
  max-width: 100%;
  background-color: white;
  margin: 25px 15px;
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}
.card .card-header {
  font-size: 24px;
  color: #343642;
  margin-bottom: 15px;
}
.card .card-header-items {
  text-align: center;
}
.card .card-header-items .header-items {
  margin: 0px 0px;
}
.media-image:hover {
  cursor: pointer;
  box-shadow: 0px 1px 5px 5px rgba(169, 169, 169, 0.6);
}
.image-show-wrapper {
  width: 100%;
}
.image-show-wrapper .image-show {
  max-width: 50%;
  margin: 0 auto;
}
.image-show-wrapper .image-show-name {
  font-size: 10px;
  text-align: center;
}
.tags-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.tags-wrapper .tags {
  max-height: 50px;
  display: flex;
  background-color: #dcdee0;
}
.tags-wrapper .tags .tag-name {
  margin: auto 0;
  padding: 12px;
  color: #343642;
  font-weight: bold;
  font-size: 16px;
  background: rgb(237, 238, 239);
}
.tags-wrapper .tags .tag-action {
  margin: auto 0;
}
.media-button {
  text-align: center;
}
.media-button .input-media {
  /* color: rgb(82, 90, 104); */
  display: none;
}
.dialog-close {
  margin-left: -75px;
  position: absolute;
}
@media only screen and (max-width: 959px) {
  .card .card-header {
    text-align: center;
  }
  .card .card-header-items .header-items {
    margin: 5px 5px 10px 5px;
  }
}
</style>
